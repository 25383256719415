/* You can add global styles to this file, and also import other style files */

*{
  font-family: "Poppins", sans-serif;
}

.altoContraste {
  -webkit-filter: invert(1);
  filter: invert(1);
  background-color: #fff;
}

